"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _i18n = require("@/assets/js/i18n");

//
//
//
//
//
//
//
//
var _default = {
  props: {
    counting: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "获取验证码"
    }
  },
  data: function data() {
    return {
      countDown: 5,
      timer: null
    };
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onClick: function onClick() {
      if (!this.counting) {
        this.$emit("count");
      }
    }
  },
  watch: {
    counting: function counting(val) {
      var _this = this;

      if (val) {
        this.countDown = 60;
        this.timer = setInterval(function () {
          if (_this.countDown == 0) {
            clearInterval(_this.timer);

            _this.$emit("update:counting", false);

            return;
          }

          _this.countDown--;
        }, 1000);
      } else {
        this.countDown = 60;
        clearInterval(this.timer);
      }
    }
  }
};
exports.default = _default;